<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                سجل الطلبات
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>المستخدم</th>
                            <th>التاريخ</th>
                            <th>
                                الخدمة
                            </th>
                            <th>
                                المبلغ (نسبتك)
                            </th>
                            <th>
                                تمت المحاسبة
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="u in orders" :key="u._id">
                                <td>
                                    {{ u.user_name }}
                                </td>
                                <td>
                                    {{ u.date }}
                                </td>
                                <td>
                                    {{ u.service }}
                                </td>
                                <td>
                                    {{ u.original * u.reseller_percent }}
                                </td>
                                <td>
                                    <span v-if="u.reseller_withdraw" class="text-success">
                                        <i class="fa fa-check"></i> تمت المحاسبة
                                    </span>
                                    <span v-if="!u.reseller_withdraw" class="text-warning">
                                        <i class="fa fa-clock-o"></i> لا
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            orders: []
        }
    },
    created(){
        var g = this;
        $.post(api + '/reseller/orders', {
            jwt: this.user.jwt
        }).then(function(r){
            g.orders = r.response;
        })
    }
}
</script>

<style>

</style>